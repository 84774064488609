import { CmsResponsiveImage } from "components/CmsResponsiveImage";
import { standardBreakpointBuilder } from "components/Flexihero";
import {
    HeroBreakpointSettings,
    TextColor,
} from "components/Flexihero/HeroBreakpoint.interfaces";
import { HeroSet } from "components/Flexihero/HeroSet";
import { StreamfieldBlock } from "interfaces";

import { BackgroundColorChoice, CmsHero, heroColorSettings } from ".";

import heroStyles from "./hero-blocks.module.scss";

export interface SlimHeroCmsData extends CmsHero {
    /** "auto" is for situations where the the CMS user didn't pick an image */
    text_color: "auto" | TextColor;
    background_color: BackgroundColorChoice;
}

export interface Props {
    cmsData: SlimHeroCmsData;
    className?: string;
}

export type SlimHeroBlock = StreamfieldBlock<"slim_hero", SlimHeroCmsData>;

export const SlimHero = ({ cmsData: { ...props }, className }: Props) => {
    const colorSettings = heroColorSettings(props.background_color);
    colorSettings.textColor =
        props.text_color === "auto"
            ? colorSettings.textColor
            : props.text_color;

    const baseSettings: HeroBreakpointSettings = {
        heroPlacement: "background-full",
        heroDirection: "row",
        mediaPlacement: "none",
        contentWidth: "full",
        contentWidthCustom: "",
        contentAlignPrimary: "middle",
        contentAlignSecondary: "middle",
        verticalMargin: "zero",
        backgroundColorPlacement: "full",
        mediaAspectRatio: "none",
        textAlignment: "center",
        ...colorSettings,
    };
    /*
  contentWidth: slimhero content can go full width, imagebackgroundhero content only takes up 50%
  minHeight: one has a minheight of 400 px, slim hero can be shorter,
  */

    const set = standardBreakpointBuilder({
        desktop: {
            content: props.content || "",
            media: props.media.image && (
                <CmsResponsiveImage
                    cmsImage={props.media.image}
                    cldSrcSet={[638, 1275, 2550, 3825]}
                    sizes="100vw"
                    cloudinaryProps={{
                        aspectRatio: "85:16",
                        crop: "fill",
                    }}
                    decorative={true}
                />
            ),
            settings: {
                ...baseSettings,
                mediaPlacement: "background",
            },
            styles: {
                mediaWrapperClassName: props.media.image
                    ? heroStyles.hasOverlay
                    : "",
                contentClassName: heroStyles.slimHeroContent,
            },
        },
        mobile: props.media.image
            ? {
                  content: props.content || "",
                  media: (
                      <CmsResponsiveImage
                          cmsImage={props.media.image}
                          cldSrcSet={[400, 800, 1200, 1600]}
                          sizes="100vw"
                          cloudinaryProps={{
                              aspectRatio: "8:5",
                              crop: "fill",
                          }}
                          decorative={true}
                      />
                  ),
                  settings: {
                      ...baseSettings,
                      heroPlacement: "full",
                      heroDirection: "column",
                      mediaPlacement: "inline",
                      contentAlignPrimary: "middle",
                      contentAlignSecondary: "right/bottom",
                      backgroundColor: "transparent",
                      textColor: "inherit",
                      mediaAspectRatio: "none",
                  },
                  styles: {
                      contentWrapperClassName: heroStyles.borderBottom,
                  },
              }
            : undefined,
    });
    return <HeroSet {...set} className={className} />;
};
