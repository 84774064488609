import classNames from "classnames";
import Clickable from "components/Clickable";
import { doesNotPreferReducedMotion } from "helpers/utils";
import { useEffect, useRef, useState } from "react";
import { CloudinaryVideo, CloudinaryVideoSources } from "react-tiny-cloudinary";

import Pause from "../../public/svg/pause.svg";
import Play from "../../public/svg/play.svg";

import styles from "./cms-video.module.scss";

type VideoProps = React.DetailedHTMLProps<
    React.VideoHTMLAttributes<HTMLVideoElement>,
    HTMLVideoElement
>;

export type Props = VideoProps & {
    file: string;
    respectPreferReducedMotion?: boolean;
    control: {
        visibility: "none" | "hover" | "visible" | "native";
        placement?: "center" | "corner";
    };
};

export const CmsVideo = ({
    file,
    control: { visibility, placement = "center" },
    respectPreferReducedMotion = false,
    ...videoProps
}: Props) => {
    const reduceMotion =
        !doesNotPreferReducedMotion() && respectPreferReducedMotion;
    const willAutoplay = videoProps.autoPlay && !reduceMotion;
    const nativeControls = useIsIos() || visibility === "native";

    const [videoPause, setVideoPause] = useState(!willAutoplay);
    const [playControlPressedOnce, setPlayControlPressedOnce] = useState(false);

    const video = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (video.current) {
            if (videoPause) {
                try {
                    video.current.pause();
                } catch (error) {
                    console.error(error);
                }
            } else {
                try {
                    video.current.play();
                } catch (error) {
                    console.error(error);
                }
            }
        }
    }, [videoPause]);

    return (
        <>
            <CloudinaryVideo
                cldId={file}
                type="upload"
                quality={undefined}
                dpr={undefined}
                defaultImage={undefined}
                poster={
                    videoProps.poster
                        ? { cldId: videoProps.poster, type: "fetch" }
                        : undefined
                }
            >
                <video
                    {...videoProps}
                    ref={video}
                    autoPlay={willAutoplay || undefined}
                    muted={videoProps.muted ?? true}
                    controls={nativeControls}
                    playsInline={videoProps.playsInline ?? true}
                >
                    <CloudinaryVideoSources sourceTypes={undefined} />
                </video>
            </CloudinaryVideo>

            {visibility !== "none" && !nativeControls && (
                <div
                    className={classNames({
                        [styles.controlWrapper]: true,
                        [styles.controlsInCenter]: placement === "center",
                        [styles.controlsInCorner]: placement === "corner",
                        [styles.visibleOnHover]: visibility === "hover",
                        [styles.forceShowPlay]:
                            playControlPressedOnce === false &&
                            visibility === "hover" &&
                            willAutoplay === false,
                    })}
                >
                    <Clickable
                        className={styles.control}
                        unstyled={true}
                        onClick={() => {
                            setPlayControlPressedOnce(true);
                            setVideoPause(!videoPause);
                        }}
                    >
                        {videoPause ? <Play /> : <Pause />}
                    </Clickable>
                </div>
            )}
        </>
    );
};

let isIosCache: boolean | undefined = undefined;

function useIsIos() {
    const [isIos, setIsIos] = useState(!!isIosCache);
    useEffect(() => {
        if (typeof isIosCache === "undefined") {
            isIosCache = /(iphone|ipad)/i.test(navigator.userAgent);
        }
        setIsIos(isIosCache);
    }, []);
    return isIos;
}
